//WILL MATCH 
//http://localhost:8000/vqs/anything/anything
import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import ReactPaginate from 'react-paginate';
import "./style.css";
let json_current = require("assets/mcqs.json");




function MCQS (props) {
const [isActive, setActive] = useState(false);
const [ToggleExplanationShowHide, setToggleExplanationShowHide] = useState(false);
const [AnswerCorrect_or_Incorrect, setAnswerCorrect_or_Incorrect] = useState(false);
const [ToggleLabShowHide, setToggleLabShowHide] = useState(false);
const [OptionTracker, setOptionTracker] = useState([]);

useEffect(()=>{

  props.CURRENT_QUESTION_JSON_DATA.Options=shuffle(props.CURRENT_QUESTION_JSON_DATA.Options)

  InitOptionTracker()

},[])

function shuffle(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function HandleQuestionChange(data){
  props.HandlePaginationChange(data)
  InitOptionTracker()

}

function InitOptionTracker(){
  var obj = []
  props.CURRENT_QUESTION_JSON_DATA.Options.map((option,index)=>{

    obj[index]=unanswered//can be unanswered, correct, incorrect
    
  })
  console.log(obj)
  setOptionTracker(obj)
  console.log(OptionTracker)
}

function checkAnswer(option,index, correctAnswer) {
  if (option === correctAnswer) {
    toggleExplanationShowHide();
    console.log("Correct");
    setOptionsClassName(option,index,correct)
    return true;
  } else {
    setOptionsClassName(option,index,incorrect)
    console.log("Incorrect index "+index);
    return false;
  }
}
const unanswered="set-question--choice"
const correct = "set-question--choice correct"
const incorrect="set-question--choice incorrect"

function setOptionsClassName(option,index,state=unanswered) {
  const obj = [...OptionTracker]

  
  if (state === unanswered) {
    //console.log("Unanswered");
    return 'set-question--choice';
  } else if (state === correct) {
    //console.log("Setting Correct Classname");
    obj[index] = correct
    setOptionTracker(obj)
    return 'set-question--choice correct';
  }
  else if (state === incorrect) {
    //console.log("Setting Classname Incorrect");
    obj[index] = incorrect
    setOptionTracker(obj)
   // console.log("After Setting:")
   // console.log(OptionTracker)
    return 'set-question--choice incorrect';
  }
}
function getOptionsClassName(option,index) {
  var state = OptionTracker[index]
  console.log("Getting ClassName For index "+index+" \n"+state)
  if (state === unanswered) {
   // console.log("Unanswered");
    return 'set-question--choice';
  } else if (state === correct) {
    //console.log("Correct");
   
    return 'set-question--choice correct';
  }
  else if (state === incorrect) {
   // console.log("Incorrect");
    return 'set-question--choice incorrect';
  }
}
function funAnswerCorrect_or_Incorrect(){
  setAnswerCorrect_or_Incorrect(!AnswerCorrect_or_Incorrect);
  //console.log("ToggleShowHide:" + ToggleShowHide)
 // console.log("className_explanation_show_hide:" + className_explanation_show_hide)
}
var className_answer_correct_or_incorrect = AnswerCorrect_or_Incorrect ? 'set-question--choice correct' : 'set-question--choice incorrect';

function toggleExplanationShowHide(){
  setToggleExplanationShowHide(!ToggleExplanationShowHide);
  //console.log("ToggleShowHide:" + ToggleShowHide)
 // console.log("className_explanation_show_hide:" + className_explanation_show_hide)
}
function toggleLabsShowHide(){

  setToggleLabShowHide(!ToggleLabShowHide);
  //console.log("ToggleShowHide:" + ToggleLabShowHide)
 // console.log("className_explanation_show_hide:" + className_explanation_show_hide)
}
var className_lab_show_hide = ToggleLabShowHide ? 'lab-value-box open' : 'lab-value-box close';

var className_explanation_show_hide = ToggleExplanationShowHide ? 'slider' : 'slider close';
  const toggleClass = () => {
    setActive(!isActive);
  };

return(
  <div>
            <div className={className_explanation_show_hide} id="explanation-slider">
              <div className="columns is-centered">
                <div className="column is-12-mobile is-10-tablet is-8-desktop clinical-case-explanation">
                  <button onClick={toggleExplanationShowHide} className="button is-fullwidth is-link">Close</button>
                  <br />
                  <h1 className="has-text-grey-light is-size-5">
                    Explanation generated for Muneeb Akhter at
                    2021-08-07 20:23:19 +0500
                    <p />
                  </h1>
                  <hr className="medangle-gradient" />
                  <p />
                  <p>
                    {props.CURRENT_QUESTION_JSON_DATA.Explanation}
                  </p>
                  <p />
                  <p>
                    <strong>Source/Reference:</strong>{" "}
                  </p>
                  <p>
                    {props.CURRENT_QUESTION_JSON_DATA.Reference}
                  </p>
                  <p />
                </div>
              </div>
            </div>
<div className="columns is-centered deprecated">
<div className={"slider close"}>

    <div className="column is-centered is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-7-widescreen is-offset-3-widescreen">
      <div className="columns is-centered">
        <div className="column is-6 global-background">
        <button onClick={toggleExplanationShowHide} className="button is-fullwidth is-link">Close</button>
          <div className="mcq-explanation">
            <p>
              This patient is undergoing metabolic acidosis due to diabetic
              ketoacidosis (DKA). DKA is a medical emergency that presents
              mostly in patients with type 1 diabetes. The most important
              biochemical features of DKA are:
              <br />
              -ketonuria of 2+ or more
              <br />
              -blood glucose &gt;200mg/dL <br />
              -metabolic acidosis <br />
              It can present with general findings seen in diabetes, such as
              polyuria and thirst, along with weakness, blurred vision,
              dehydration, hypotension, tachycardia, hypothermia, Kussmaul
              breathing, sickly-sweet smell of acetone, and even coma.
              <br />
              The major features in any type of metabolic acidosis are low blood
              pH (&lt;7.35) and low bicarbonate levels (&lt;24 mmol/L). pCO2 is
              generally increased as there is less base to maintain the pH,
              although the body's compensatory response ultimately leads to a
              decrease in pCO2 levels which occurs by compensatory
              hyperventilation, ultimately taking the form of Kussmaul's
              breathing pattern (deep labored breathing).
            </p>
            <span className="is-size-5-desktop is-size-6-tablet is-size-7-mobile">
              Explanation generated for Muneeb Akhter at 2021-07-29 15:42:34
              +0000
            </span>
          </div>
          <hr />
          <p className="has-text-weight-semibold global-text is-size-6-desktop">
            <i className="fas fa-book" />
            References
          </p>
          <div className="auto-link-source global-text">
            Ralston S, Penman ID, Strachan MWJ, Hobson RP, Britton R, Davidson
            S. Davidson’s Principles and Practice of Medicine. 23rd ed.
            Elsevier; 2018: 365.
          </div>
          <hr />
          <p className="has-text-weight-semibold global-text is-size-6-desktop">
            <i className="fas fa-star-half-alt" /> Difficulty Rating <br />
            <span className="has-text-weight-semibold is-size-6 is-uppercase has-text-info">
              normal
            </span>
          </p>
        </div>
        <div className="column is-6 global-background">
          <div className="columns is-multiline is-centered">
            <div className="column is-12">
              <div
                id="chart-1"
                style={{
                  height: 300,
                  width: "100%",
                  textAlign: "center",
                  color: "rgb(255, 0, 0)",
                  lineHeight: 300,
                  fontSize: 14,
                  fontFamily:
                    '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
                  overflow: "hidden"
                }}
                data-highcharts-chart={0}
              >
                Error Loading Chart:{" "}
              </div>
            </div>
            <div className="column is-12">
              <p className="has-text-weight-semibold is-size-6-desktop">
                <i className="fas fa-chart-pie" /> Stats &amp; Info
              </p>
              <p className="is-size-6 global-text">
                Muneeb, this question has been attempted 211 times{" "}
                <span className="has-text-grey-light">
                  (as of Jul 29, 3:42 PM)
                </span>{" "}
              </p>
              <br />
              <table className="table is-narrow is-fullwidth">
                <tbody></tbody>
                <thead>
                  <tr>
                    <th>Answer Choice</th>
                    <th>Percentage of users that picked it</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Decreased K+</td>
                    <td>11.37</td>
                  </tr>
                  <tr>
                    <td>Increased serum pH</td>
                    <td>10.43</td>
                  </tr>
                  <tr>
                    <td>Increased Na+</td>
                    <td>9.0</td>
                  </tr>
                  <tr>
                    <td>Decreased pCO2</td>
                    <td>12.32</td>
                  </tr>
                  <tr>
                    <td>Decreased HCO3-</td>
                    <td>56.87</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
      </div>
      <button onClick={toggleExplanationShowHide} className="button is-info is-fullwidth slider-close">
        Close
      </button>
    </div>
  </div>
  </div>

  <div className="columns is-centered">
    <div className="column is-8-desktop is-10-tablet is-12-mobile">
      <div className="columns is-centered">
        <div className="column is-12-mobile is-12-tablet is-12-desktop">
          <div className="relevancy">
            <div className="suggested-mcq-exam-purpose">
              <span className="icon is-medium">
                <i className="fas fa-broadcast-tower" />
              </span>
              <p>{props.CURRENT_QUESTION_JSON_DATA.Relevancy}</p>
            </div>
            <div>
              <div className="suggested-mcq-exam-purpose">
                <span className="icon is-medium is-black-text">
                  <i className="fas fa-tags" />
                </span>
                <p>{props.CURRENT_QUESTION_JSON_DATA.Tags}</p>
              </div>
            </div>
          </div>
          <h2 className="decorated has-text-weight-bold global-text">
            <span className="is-size-7">Muneeb Akhter</span>
          </h2>
          <p className="gr-question">{props.CURRENT_QUESTION_JSON_DATA.Question}</p>

          {props.CURRENT_QUESTION_JSON_DATA.Options.map((option,index) => (
            <div className={OptionTracker[index]} id={option}>
              <div className="lights-out-on">
                <i className="fas fa-eye-slash is-size-5 global-text" />
              </div>
              <div className="set-q-text global-text">
                <a
                  onClick={() => checkAnswer(option,index, props.correctAnswer)}
                  className="answer"
                >
                  {option}
                </a>
              </div>
              <div className="set-q-id" />
            </div>
          ))}

        </div>
      </div>
      <p className="correct-choice is-hidden">{props.CURRENT_QUESTION_JSON_DATA["Answer"]}</p>
      <div id="pagination-container">
        <svg
          viewBox="0 0 100 8"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            d="M 0,4 L 100,4"
            stroke="#eee"
            strokeWidth={8}
            fillOpacity={0}
          />
          <path
            d="M 0,4 L 100,4"
            stroke="rgb(89,155,255)"
            strokeWidth={8}
            fillOpacity={0}
            style={{ strokeDasharray: "100, 100", strokeDashoffset: 100 }}
          />
        </svg>
      </div>
      
      <ReactPaginate
                   marginPagesDisplayed={2}
                   pageRangeDisplayed={5}
                   pageCount={props.totalQuestions}
                   nextLabel={"Next →"}
                   breakClassName={"gap"}
                   previousLabel={"← Previous"}
                   containerClassName={"pagination"}
                   activeClassName={"current-pagination"}
                   onPageChange={HandleQuestionChange}

                  />
                  <hr className="medangle-gradient-hairline" style={{ margin: 0 }} />
      <div className="columns is-centered">

<div className="column is-12-mobile is-10-tablet is-8-desktop">


<br/>

<center>
Muneeb Akhter, you're on question {props.currentQuestion} of <b>{props.totalQuestions}</b>
  </center>
  <progress className="progress  is-info" value={props.currentQuestion} max={props.totalQuestions}>
    {" "}
    Progress{" "}
  </progress>
  </div>
  </div>


      
      <div className="columns is-multiline is-centered has-text-centered">
        <div className="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen mcq_tagged_toolbar">
          <p

onClick={toggleLabsShowHide}

            id="lab-value-box-toggle"
            className="button is-link lab-value-link has-text-weight-bold"
          >
            <i className="fas fa-vial" /> &nbsp;{" "}
            <span className="is-hidden-mobile">Instant</span>&nbsp; Lab Values{" "}
          </p>
          <a
            href="#"
            id="report_question_button"
            className="button has-text-weight-semibold global-text"
          >
            <i className="far fa-question-circle" /> &nbsp; Report Question
          </a>
          <a
            href="#"
            id="save_question_button"
            className="button has-text-weight-semibold is-link"
          >
            <i className="fas fa-bookmark" /> &nbsp; Save Question
          </a>
          <div id="myModal2" className="modal2">
            <div className="modal-content2">
              <div className="column is-8">
                <form
                  className="new_saved_item"
                  id="new_saved_item"
                  action="/mcqs/a-45-year-old-patient-is-brought-to-the-emergency-room-in-a-delirious-state-with-weakness-blurred-vision-dehydration-and-hypothermia-he-was-diagnosed-with-type-1-diabetes-mellitus-30-years-back-on-examination-he-has-a-rapid-pulse-and-her-breathing-is-deep-and-labored-with-a-sickly-sweet-odor-his-blood-glucose-is-256-mg-dl-and-his-urinary-dipstick-shows-2-ketonuria-what-is-the-most-important-derangement-to-be-seen-on-the-arterial-blood-gases-abg-of-this-patient/saved_items"
                  acceptCharset="UTF-8"
                  data-remote="true"
                  method="post"
                >
                  <input name="utf8" type="hidden" defaultValue="✓" />
                  <textarea
                    className="textarea is-normal global-input"
                    placeholder="Add your notes here before saving this question"
                    name="saved_item[saved_item_notes]"
                    id="saved_item_saved_item_notes"
                    defaultValue={""}
                  />
                  <br />
                  <input
                    type="submit"
                    name="commit"
                    defaultValue="Save Question"
                    data-disable-with="Saving.. "
                    className="button is-info has-text-weight-semibold"
                  />
                </form>
              </div>
              <h5 id="close-review-box2">Close</h5>
            </div>
          </div>
          <br />
        </div>
      </div>
      <p />
    </div>
  </div>
  
  <div id="lab-value-box-container">
    <div  className={className_lab_show_hide}>
      <p  onClick={toggleLabsShowHide}>
        <button
          id="lab-value-inner-box-toggle"
          className="button is-small is-danger has-text-weight-bold"
          onClick={toggleLabsShowHide}
        >
          Close
        </button>
      </p>
      <input
        type="text"
        placeholder="Search Instant Lab Values"
        id="contact-filter"
        className="form-control"
      />
      <table id="contact-table" className="table mt-3 global-background">
        <tbody>
          <tr>
            <th className="global-text">Lookup</th>
            <th className="global-text">Reference Range</th>
            <th className="global-text">SI Reference</th>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Hepatic</span>
            </td>
          </tr>
          <tr>
            <td>
              Alanine aminotransferase (ALT){" "}
              <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>10–40 U/L</td>
            <td>40 U/L</td>
          </tr>
          <tr>
            <td>
              Aspartate aminotransferase (AST){" "}
              <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>12–38 U/L</td>
            <td>12–38 U/L</td>
          </tr>
          <tr>
            <td>
              Alkaline phosphatase{" "}
              <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>25–100 U/L</td>
            <td>25–100 U/L</td>
          </tr>
          <tr>
            <td>
              Amylase <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>25–125 U/L</td>
            <td>25–125 U/L</td>
          </tr>
          <tr>
            <td>
              Bilirubin, Total // Direct{" "}
              <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>0.1–1.0 mg/dL // 0.0–0.3 mg/dL</td>
            <td>2–17 μmol/L // 0–5 μmol/L</td>
          </tr>
          <tr>
            <td>
              Proteins, total <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>6.0–7.8 g/dL</td>
            <td>60–78 g/L </td>
          </tr>
          <tr>
            <td>
              Albumin <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>3.5–5.5 g/dL</td>
            <td>35–55 g/L</td>
          </tr>
          <tr>
            <td>
              Globulin <span className="is-hidden">Serum Hepatic</span>
            </td>
            <td>2.3–3.5 g/dL</td>
            <td>23–35 g/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Coagulation</span>
            </td>
          </tr>
          <tr>
            <td>
              Partial thromboplastin time (PTT) (activated){" "}
              <span className="is-hidden">Serum Coagulation</span>
            </td>
            <td>25-40 seconds</td>
            <td>25-40 Seconds</td>
          </tr>
          <tr>
            <td>
              Prothrombin time (PT){" "}
              <span className="is-hidden">Serum Coagulation</span>
            </td>
            <td>11-15 seconds</td>
            <td>11-15 seconds</td>
          </tr>
          <tr>
            <td>
              D-Dimer <span className="is-hidden">Serum Coagulation</span>
            </td>
            <td>≤250 ng/mL</td>
            <td>≤1.4 nmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Lipids</span>
            </td>
          </tr>
          <tr>
            <td>
              Cholesterol - Total{" "}
              <span className="is-hidden">Serum Lipids</span>
            </td>
            <td>
              <span className="has-text-success">Normal: &lt;200 mg/dL</span>{" "}
              <br />
              <span className="has-text-danger">High: &gt;240 mg/dL</span>
            </td>
            <td>
              &lt;5.2 mmol/L <br /> &gt;6.2 mmol/L
            </td>{" "}
          </tr>{" "}
          <tr>
            <td>
              HDL <span className="is-hidden">Serum Lipids</span>
            </td>
            <td>40–60 mg/dL</td>
            <td>1.0–1.6 mmol/L</td>
          </tr>
          <tr>
            <td>
              LDL <span className="is-hidden">Serum Lipids</span>
            </td>
            <td>&lt;160 mg/dL </td> <td>&lt;4.2 mmol/L </td>{" "}
          </tr>{" "}
          <tr>
            <td>
              Triglycerides <span className="is-hidden">Serum Lipids</span>
            </td>
            <td>
              Normal: &lt;150 mg/dL <br /> Borderline: 151–199 mg/dL{" "}
            </td>
            <td>
              &lt;1.70 mmol/L <br /> 1.71–2.25 mmol/L
            </td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">
                Serum - General Chemistry
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Urea nitrogen{" "}
              <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>7–18 mg/dL</td>
            <td>2.5–6.4 mmol/L</td>
          </tr>
          <tr>
            <td>
              Creatinine{" "}
              <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>0.6–1.2 mg/dL</td>
            <td>53–106 μmol/L</td>
          </tr>
          <tr>
            <td>
              Glucose <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>Fasting: 70–100 mg/dL</td>
            <td>3.8–5.6 mmol/L</td>
          </tr>
          <tr>
            <td>
              Calcium <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>8.4–10.2 mg/dL</td>
            <td>2.1–2.6 mmol/L</td>
          </tr>
          <tr>
            <td>
              Magnesium (Mg2+){" "}
              <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>1.5–2.0 mg/dL</td>
            <td>0.75–1.0 mmol/L</td>
          </tr>
          <tr>
            <td>
              Phosphorus (inorganic){" "}
              <span className="is-hidden">Serum General Chemistry</span>
            </td>
            <td>3.0–4.5 mg/dL</td>
            <td>1.0–1.5 mmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Electrolytes</span>
            </td>
          </tr>
          <tr>
            <td>
              Sodium (Na+)<span className="is-hidden">Serum Electrolytes</span>
            </td>
            <td>136–146 mEq/L</td>
            <td>136–146 mmol/L</td>
          </tr>
          <tr>
            <td>
              Potassium (K+)
              <span className="is-hidden">Serum Electrolytes</span>
            </td>
            <td>3.5–5.0 mEq/L</td>
            <td>3.5–5.0 mmol/L</td>
          </tr>
          <tr>
            <td>
              Chloride (Cl–)
              <span className="is-hidden">Serum Electrolytes</span>
            </td>
            <td>95–105 mEq/L</td>
            <td>95–105 mmol/L</td>
          </tr>
          <tr>
            <td>
              Bicarbonate (HCO3–)
              <span className="is-hidden">Serum Electrolytes</span>
            </td>
            <td>22–28 mEq/L</td>
            <td>22–28 mmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Iron Studies</span>
            </td>
          </tr>
          <tr>
            <td>
              Ferritin<span className="is-hidden">Serum Iron Studies</span>
            </td>
            <td>
              Male: 20–250 ng/mL <br /> Female: 10–120 ng/mL
            </td>
            <td>
              20–250 μg/L <br /> 10–120 μg/L
            </td>
          </tr>
          <tr>
            <td>
              Iron<span className="is-hidden">Serum Iron Studies</span>
            </td>
            <td>
              Male: 65–175 μg/dL <br /> Female: 50–170 μg/dL
            </td>
            <td>
              11.6–31.3 μmol/L <br /> 9.0–30.4 μmol/L
            </td>
          </tr>
          <tr>
            <td>
              Total iron-binding capacity
              <span className="is-hidden">Serum Iron Studies</span>
            </td>
            <td>250–400 μg/dL</td>
            <td>44.8–71.6 μmol/L</td>
          </tr>
          <tr>
            <td>
              Transferrin<span className="is-hidden">Serum Iron Studies</span>
            </td>
            <td>200–360 mg/dL</td>
            <td>2.0–3.6 g/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              <span className="has-text-weight-bold">Serum - Endocrine</span>
            </td>
          </tr>
          <tr>
            <td>
              Follicle-stimulating hormone
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Male: 4–25 mIU/mL
              <br />
              <br />
              Female: <br /> premenopause: 4–30 mIU/mL
              <br /> midcycle: peak 10–90 mIU/mL
              <br /> postmenopause: 40–250 mIU/mL
            </td>
            <td>
              4–25 IU/L
              <br />
              <br />
              <br />
              4–30 IU/L
              <br /> 10–90 IU/L
              <br /> 40–250 IU/L
            </td>
          </tr>
          <tr>
            <td>
              Luteinizing hormone
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Male: 6–23 mIU/mL
              <br />
              <br />
              Female: <br /> follicular phase 5–30 mIU/mL
              <br /> midcycle: 75–150 mIU/mL
              <br /> postmenopause: 30–200 mIU/mL
            </td>
            <td>
              6–23 IU/L
              <br />
              <br />
              <br />
              5–30 IU/L
              <br /> 75-150 IU/L
              <br /> 30-200 IU/L
            </td>
          </tr>
          <tr>
            <td>
              Growth hormone - arginine stimulation
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Fasting: &lt;5 ng/mL <br /> Provocative stimuli: &gt;7 ng/mL
            </td>
            <td>
              &lt;5 μg/L <br /> &gt;7 μg/L
            </td>
          </tr>
          <tr>
            <td>
              Prolactin (hPRL)<span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Male: &lt;17 ng/mL <br /> Female: &lt;25 ng/mL
            </td>
            <td>
              &lt;17 μg/L <br /> &lt;25 μg/L
            </td>
          </tr>
          <tr>
            <td>
              Cortisol<span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              0800 h: 5–23 μg/dL <br /> 1600 h: 3–15 μg/dL <br /> 2000 h:
              &lt;50% of 0800 h{" "}
            </td>
            <td>
              138–635 nmol/L <br /> 82–413 nmol/L <br /> Fraction of 0800 h:
              &lt;0.50
            </td>
          </tr>
          <tr>
            <td>
              TSH<span className="is-hidden">Serum Endocrine</span>
            </td>
            <td> 0.4–4.0 μU/mL</td>
            <td>0.4–4.0 mIU/L</td>
          </tr>
          <tr>
            <td>
              Triiodothyronine (T3) (RIA)
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>100–200 ng/dL</td>
            <td>1.5–3.1 nmol/L</td>
          </tr>
          <tr>
            <td>
              Triiodothyronine (T3) resin uptake
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>25%–35%</td>
            <td>0.25–0.35</td>
          </tr>
          <tr>
            <td>
              Thyroxine (T4)<span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>5–12 μg/dL</td>
            <td>64–155 nmol/L</td>
          </tr>
          <tr>
            <td>
              Free T4<span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>0.9–1.7 ng/dL</td>
            <td>12.0–21.9 pmol/L</td>
          </tr>
          <tr>
            <td>
              Thyroidal iodine (123I) uptake
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>8%–30% of administered dose/24 h </td>
            <td>0.08–0.30/24 h</td>
          </tr>
          <tr>
            <td>
              Intact PTH <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>10–60 pg/mL</td>
            <td>10–60 ng/L</td>
          </tr>
          <tr>
            <td>
              17-Hydroxycorticosteroids
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Male: 3.0–10.0 mg/24 h <br /> Female: 2.0–8.0 mg/24 h{" "}
            </td>
            <td>
              8.2–27.6 μmol/24 h <br /> 5.5–22.0 μmol/24 h
            </td>
          </tr>
          <tr>
            <td>
              17-Ketosteroids, total
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>
              Male: 8–20 mg/24 h <br /> Female: 6–15 mg/24 h{" "}
            </td>
            <td>
              {" "}
              28–70 μmol/24 h <br /> 21–52 μmol/24 h
            </td>
          </tr>
          <tr>
            <td>
              Hemoglobin A1C <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>≤6%</td>
            <td>≤42 mmol/mol</td>
          </tr>
          <tr>
            <td>
              Hemoglobin, plasma{" "}
              <span className="is-hidden">Serum Endocrine</span>
            </td>
            <td>&lt;4 mg/dL</td>
            <td>&lt;0.62 mmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">
                Serum - Immunoglobulins
              </span>
            </td>
          </tr>
          <tr>
            <td>
              IgA <span className="is-hidden">Serum Immunoglobulins</span>
            </td>
            <td>76–390 mg/dL</td>
            <td>0.76–3.90 g/L</td>
          </tr>
          <tr>
            <td>
              IgE <span className="is-hidden">Serum Immunoglobulins</span>
            </td>
            <td>0–380 IU/mL</td>
            <td>0–380 kIU/L</td>
          </tr>
          <tr>
            <td>
              IgG <span className="is-hidden">Serum Immunoglobulins</span>
            </td>
            <td>650–1500 mg/dL </td>
            <td>6.5–15.0 g/L</td>
          </tr>
          <tr>
            <td>
              IgM <span className="is-hidden">Serum Immunoglobulins</span>
            </td>
            <td>50–300 mg/dL</td>
            <td>0.5–3.0 g/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">Serum - Other</span>
            </td>
          </tr>
          <tr>
            <td>
              Creatine kinase <span className="is-hidden">Serum Other</span>
            </td>
            <td>
              Male: 25–90 U/L <br /> Female: 10–70 U/L
            </td>
            <td>
              25–90 U/L <br /> 10–70 U/L{" "}
            </td>
          </tr>
          <tr>
            <td>
              Lactate dehydrogenase{" "}
              <span className="is-hidden">Serum Other</span>
            </td>
            <td>45–200 U/L</td>
            <td>45–200 U/L</td>
          </tr>
          <tr>
            <td>
              Osmolality (Serum) <span className="is-hidden">Serum Other</span>
            </td>
            <td>275–295 mOsmol/kg H2O</td>
            <td>275–295 mOsmol/kg H2O </td>
          </tr>
          <tr>
            <td>
              Uric acid <span className="is-hidden">Serum Other</span>
            </td>
            <td>3.0–8.2 mg/dL</td>
            <td>0.18–0.48 mmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">
                Gases, arterial blood (Room Air)
              </span>
            </td>
          </tr>
          <tr>
            <td>
              PO2{" "}
              <span className="is-hidden">
                Gases, arterial blood (Room Air)
              </span>
            </td>
            <td>75–105 mm Hg</td>
            <td> 10.0–14.0 kPa</td>
          </tr>
          <tr>
            <td>
              PCO2{" "}
              <span className="is-hidden">
                Gases, arterial blood (Room Air)
              </span>
            </td>
            <td>33–45 mm Hg</td>
            <td>4.4–5.9 kPa</td>
          </tr>
          <tr>
            <td>
              pH{" "}
              <span className="is-hidden">
                Gases, arterial blood (Room Air)
              </span>
            </td>
            <td>7.35–7.45</td>
            <td>[H+] 36–44 nmol/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">
                Cerebrospinal Fluid - CSF
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Cell count{" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>0–5/mm3</td>
            <td>0–5 × 106/L</td>
          </tr>
          <tr>
            <td>
              Chloride{" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>118–132 mEq/L</td>
            <td>118–132 mmol/L</td>
          </tr>
          <tr>
            <td>
              Gamma globulin{" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>3%–12% total proteins</td>
            <td>0.03–0.12</td>
          </tr>
          <tr>
            <td>
              Glucose (CSF){" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>40–70 mg/dL</td>
            <td>2.2–3.9 mmol/L</td>
          </tr>
          <tr>
            <td>
              Pressure{" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>70–180 mm H2O</td>
            <td>70–180 mm H2O</td>
          </tr>
          <tr>
            <td>
              Proteins, total{" "}
              <span className="is-hidden">Cerebrospinal Fluid (CSF)</span>
            </td>
            <td>&lt;40 mg/dL</td>
            <td>&lt;0.40 g/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Hematocrit{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 41%–53% <br /> Female: 36%–46%{" "}
            </td>
            <td>
              0.41–0.53 <br /> 0.36–0.46
            </td>
          </tr>
          <tr>
            <td>
              Hemoglobin, blood{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 13.5–17.5 g/dL <br /> Female: 12.0–16.0 g/dL
            </td>
            <td>
              135–175 g/L <br /> 120–160 g/L{" "}
            </td>
          </tr>
          <tr>
            <td>
              Mean corpuscular hemoglobin (MCH){" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>25–35 pg/cell</td>
            <td>0.39–0.54 fmol/cell</td>
          </tr>
          <tr>
            <td>
              Mean corpuscular hemoglobin conc. (MCHC){" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>31%–36% Hb/cell</td>
            <td>4.8–5.6 mmol Hb/L</td>
          </tr>
          <tr>
            <td>
              Mean corpuscular volume (MCV){" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>80–100 μm3</td>
            <td>80–100 fL</td>
          </tr>
          <tr>
            <td>
              Volume - Plasma{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 25–43 mL/kg <br /> Female: 28–45 mL/kg
            </td>
            <td>
              0.025–0.043 L/kg <br /> 0.028–0.045 L/kg
            </td>
          </tr>
          <tr>
            <td>
              Volume - Red Cell{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 20–36 mL/kg <br /> Female: 19–31 mL/kg
            </td>
            <td>
              0.020–0.036 L/kg <br /> 0.019–0.031 L/kg
            </td>
          </tr>
          <tr>
            <td>
              Platelet count{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>150,000–400,000/mm3</td>
            <td>150–400 × 109/L </td>
          </tr>
          <tr>
            <td>
              Reticulocyte count{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>0.5%–1.5%</td>
            <td>0.005–0.015</td>
          </tr>
          <tr>
            <td>
              Erythrocyte count{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 4.3–5.9 million/mm3 <br /> Female: 3.5–5.5 million/mm3
            </td>
            <td>
              4.3–5.9 × 1012/L <br /> 3.5–5.5 × 1012/L
            </td>
          </tr>
          <tr>
            <td>
              Erythrocyte sedimentation rate (Westergren){" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>
              Male: 0–15 mm/h <br /> Female: 0–20 mm/h
            </td>
            <td>
              0–15 mm/h <br /> 0–20 mm/h
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              CD4+ T-lymphocyte count{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>&gt;500/mm3</td>
            <td>&gt;0.5 × 109/L</td>
          </tr>
          <tr>
            <td>
              Troponin I{" "}
              <span className="is-hidden">
                Hematologic - Complete Blood Count (CBC)
              </span>
            </td>
            <td>≤0.04 ng/mL</td>
            <td>≤0.04 μg/L</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Leukocyte count (WBC){" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>4500–11,000/mm3</td>
            <td>4.5–11.0 × 109/L</td>
          </tr>
          <tr>
            <td>
              Neutrophils, segmented{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>54%–62%</td>
            <td>0.54–0.62</td>
          </tr>
          <tr>
            <td>
              Neutrophils, bands{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>3%–5%</td>
            <td>0.03–0.05</td>
          </tr>
          <tr>
            <td>
              Eosinophils{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>1%–3%</td>
            <td>0.01–0.03</td>
          </tr>
          <tr>
            <td>
              Basophils{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>0%–0.75%</td>
            <td>0.00–0.0075 </td>
          </tr>
          <tr>
            <td>
              Lymphocytes{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>25%–33%</td>
            <td>0.25–0.33</td>
          </tr>
          <tr>
            <td>
              Monocytes{" "}
              <span className="is-hidden">
                Leukocyte Count - White Blood Cells (WBC)
              </span>
            </td>
            <td>3%–7%</td>
            <td>0.03–0.07</td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">Urine</span>
            </td>
          </tr>
          <tr>
            <td>
              Calcium (Urine) <span className="is-hidden">Urine</span>
            </td>
            <td>100–300 mg/24 h</td>
            <td>2.5–7.5 mmol/24 h</td>
          </tr>
          <tr>
            <td>
              Creatinine clearance (Urine){" "}
              <span className="is-hidden">Urine</span>
            </td>
            <td>
              Male: 97–137 mL/min <br /> Female: 88–128 mL/min{" "}
            </td>
            <td>
              97–137 mL/min <br /> 88–128 mL/min
            </td>
          </tr>
          <tr>
            <td>
              Osmolality (Urine) <span className="is-hidden">Urine</span>
            </td>
            <td>50–1200 mOsmol/kg H2O </td>
            <td>50–1200 mOsmol/kg H2O </td>
          </tr>
          <tr>
            <td>
              Oxalate (Urine) <span className="is-hidden">Urine</span>
            </td>
            <td>8–40 μg/mL </td>
            <td>90–445 μmol/L </td>
          </tr>
          <tr>
            <td>
              Proteins, total (Urine) <span className="is-hidden">Urine</span>
            </td>
            <td>&lt;150 mg/24 h </td>
            <td>&lt;0.15 g/24 h </td>
          </tr>
          <tr className="values-table-stripe">
            <td colSpan={3}>
              {" "}
              <span className="has-text-weight-bold">Misc</span>
            </td>
          </tr>
          <tr>
            <td>
              Body Mass Index (BMI) <span className="is-hidden">BMI</span>
            </td>
            <td>Adult: 19–25 kg/m2</td>
            <td>Adult: 19–25 kg/m2</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  </div>
);
          }
MCQS.defaultProps = {
  //CURRENT_QUESTION_JSON_DATA
  Question:
    "A 45-year-old patient is brought to the emergency room in adelirious state with weakness, blurred vision, dehydration, and hypothermia. He was diagnosed with type 1 diabetes mellitus 30 years back. On examination, he has a rapid pulse and her breathing is deep and labored, with a sickly sweet odor. His blood glucose is 256mg/dL and his urinary dipstick shows +2 ketonuria. What is the most important derangement to be seen on the arterial blood gases (ABG) of this patient?",
  correctAnswer: "Decreased HCO3-",
  Options: [
    "Increased Na+",
    "Decreased HCO3-",
    "Decreased pCO2",
    "Decreased K+",
    "Increased serum pH"
  ],
  Tags: "ELECTROLYTES AND ACID-BASE BALANCE MEDICINE-2",
  Televancy: "SUGGESTED QUESTION",
  totalQuestions: 27,
  currentQuestion: 2
};




function MCQSPage(props) {
  
    const [isActive, setActive] = useState(false);
    const [TagFound,setTagFound] = useState(false);
    //props
    const [current_url_page_param, setcurrent_url_page_param] = useState(false);
    const [json_index, setJSONindex] = useState(0);

    const [last_question, setLastQuestion] = useState(false);
    const [first_question, setFirstQuestion] = useState(false);

    const [json_data, setJSONdata] = useState(json_current);

    const [correctAnswer, setcorrectAnswer] = useState(false);

    const [tags, settags] = useState(false);

    const [relevancy, setrelevancy] = useState(false);

    const [currentQuestion, setcurrentQuestion] = useState(false);


    const [totalQuestions, settotalQuestions] = useState(false);

    const [options, setoptions] = useState(false);
    const [question, setquestion] = useState(false);

    const setjson = (data) => {

        setcorrectAnswer(data.correctAnswer);
        settags(data.tags);
        setrelevancy(data.relevancy);
        setcurrentQuestion(data.currentQuestion);
        settotalQuestions(data.totalQuestions);
        setoptions(data.options);
        setquestion(data.question);
      };

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(()=>{
    FilterJSONfoTopic();
    
 
 
     setMCQbasedonPageParam();
   }, [])
 
   function FilterJSONfoTopic(){
     var topic_to_filter = props.params.topic;
     const filtered_json_data = json_data.filter(question => question["Topic"]==topic_to_filter);
     console.log(filtered_json_data.length);
     if(filtered_json_data.length === 0){
       setTagFound(false)
       console.log("BITXCH YOU")
     }
     else{
      // console.log("TH HELL YOU")
       setTagFound(true)
       setJSONdata(filtered_json_data)
     }
     
 
   }
 
 function setMCQbasedonPageParam(){
   var params = new URLSearchParams(props.location.search);
     if(params.has("page") === false)
     {
     } 
     else{
       var requiredQuestion = params.get("page");
     
         setJSONindex(requiredQuestion-1);
       
       
     }
 
 }

  function nextMCQ (){
    var params = new URLSearchParams(props.location.search);
    if(params.has("page") === false)
    {
      if(json_index === 0)
      {
        setJSONindex(json_index+1);
      }
      else if(json_index=== (json_data.length - 1))//last question require
      {
       // FixJSONOptioninjson_data(json_index+1)
      //  setJSONindex(json_index+1);
       
      }
      else{
        setJSONindex(json_index+1);
        //console.log("json_index: "+json_index);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
        setJSONindex(json_index+1);
      }
      else{
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }
  function previousMCQ(){
    var params = new URLSearchParams(props.location.search);
    if(!params.has("page"))
    {
      if(json_index === 0) //already first mcq
      {
      
      }
      else{
       
        setJSONindex(json_index-1);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
      
        setJSONindex(json_index-1);
      }
      else{
       
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }
  function FixJSONOptioninjson_data(index){//DEPRECATED // already fixed using python
    //eg Options:"Increased Na+ |\nIncreased serum pH |\nDecreased pCO2 |\nDecreased HCO3- |\nDecreased K+ |"
    //after conversion: [Increased Na+ , Increased serum pH , Decreased pCO2 , Decreased HCO3- , Decreased K+] // 5 length
    if (typeof json_data[index].Options === 'string'){
      const optionarray = json_data[index].Options.split("|");
      optionarray.splice(-1);
     
      let trimedArr = optionarray.map(str => str.trim());
      json_data[index].Options = optionarray;
    }
    if (typeof json_data[index].Options === 'string'){
     console.log("string")
    }
    if (Array.isArray(json_data[index].Options)){
      console.log("array")
      const ar = json_data[json_index].Options;
      ar.map((data)=>{console.log(data)})
     }
    

   // return optionarray;

  }
  function ManualChangeJSONIndex(index_to_change_to){
    
    setJSONindex(index_to_change_to)

    
  }

function HandlePaginationChange(data){
  //data={selected:x}
  //where x is number which is selected
console.log(data)
ManualChangeJSONIndex(data.selected)
}
function CheckNavigate(){//not working
  var i = json_index+2
  navigate(props.location.pathname +"?page="+i)
}

  function getURLparam(urlparamname = "page") {
    var params = new URLSearchParams(props.location.search);
    if (params.has(urlparamname)) {
      return params.get(urlparamname);
    }
    else { return false }
  }
  //egpath => /smart-sets/anymodule/anytopic
  //props.params => contain => props.params.module and props.params.topic
  //props.params.module = anymodule  (how => /smart-sets/* => /smart-sets/anymodule)
  //props.params.topic = anytopic (how  => /smart-sets/*/* => /smart-sets/anymodule/anytopic)
  //props.location.search = for => /smart-sets/module?param
  //props.location.pathname => "pathname":"/mcqs/any/cerebellum-anatomy
    return (
        <Layout>
            <Seo title="MCQS" />
            <div>
              
                <MCQS
                    CURRENT_QUESTION_JSON_DATA={json_data[json_index]}//contain everything
                    correctAnswer={json_data[json_index].Answer}
                    Tags={json_data[json_index].Tags}
                    Relevancy={json_data[json_index].Relevancy}
                    currentQuestion={json_index+1}
                    totalQuestions={json_data.length}
                    Options={json_data[json_index].Options}
                    Reference = {json_data[json_index].Reference}
                    Difficulty = {json_data[json_index].Difficulty}
                    Attempts = {json_data[json_index].Attempts}
                    ScrappedTopicTotalQuestion =  {json_data[json_index].TotalQuestion}
                    Question={json_data[json_index].Question}
                    Explanation={json_data[json_index].Question}

                    previousMCQ={previousMCQ}
                    nextMCQ={nextMCQ}
                    HandlePaginationChange={HandlePaginationChange}
                    
                    />
            </div>
        </Layout>
    )
}

export default MCQSPage

/*
  <button onClick={CheckNavigate}>navigate</button>
                <h1>{JSON.stringify(props.params)}</h1>
                <h2>{JSON.stringify(props.location)}</h2>
<h1>{JSON.stringify(props.params)}</h1>
                <h2>{JSON.stringify(json_data[0])}</h2>
                <h2>index: {json_index}</h2>
                <h2>question:   {json_data[json_index].Question}</h2>
                <h2>   options={json_data[json_index].Options}</h2>
             
              
<button onClick={nextMCQ}>nextMCQ</button>
<button onClick={previousMCQ}>previousMCQ</button>
                <Link to={props.location.pathname +"?page="+json_index+2}>change url</Link>
                <button navigate={props.location.pathname +"?page="+json_index+2} >change url</button>
*/
